import { Controller } from "@hotwired/stimulus";
import SignaturePad from "signature_pad";
import Swal from "sweetalert2";

// Connects to data-controller="signature"
export default class extends Controller {
  static targets = ["modal", "canvas", "input", "preview"];

  connect() {}

  show(event) {
    event.preventDefault();

    this._showModal();
    this._createSignaturePad();
  }

  sign(event) {
    event.preventDefault();

    // Check to see if a signature is present
    if (!this._signaturePad.isEmpty()) {
      const signature = this._signaturePad.toDataURL("image/svg+xml");
      this.inputTarget.value = signature;
      this._generatePreview(signature);

      this.hide();
    } else {
      Swal.fire({
        icon: "error",
        text: "A signature is required",
      });
    }
  }

  resizeSignaturePad() {
    if (this._signaturePad) {
      this._signaturePad.clear();

      let ratio = Math.max(window.devicePixelRatio || 1, 1);

      this.canvasTarget.width = this.canvasTarget.offsetWidth * ratio;
      this.canvasTarget.height = this.canvasTarget.offsetHeight * ratio;

      this.canvasTarget.getContext("2d").scale(ratio, ratio);
    }
  }

  hide() {
    this.modalTarget.style.display = "none";
  }

  _showModal() {
    this.modalTarget.style.display = "block";
  }

  _createSignaturePad() {
    this._signaturePad = new SignaturePad(this.canvasTarget, {
      backgroundColor: "rgb(0, 0, 0, 0)",
    });

    this.resizeSignaturePad();
  }

  _generatePreview(signature) {
    let img = document.createElement("img");
    img.src = signature;

    this.previewTarget.innerHTML = "";
    this.previewTarget.appendChild(img);
    this.previewTarget.classList.remove("d-none");
  }
}
