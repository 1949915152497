import WysiwygController from "../wysiwyg_controller";

const styles = `
  @media screen {
    html {
      background: #f4f4f4;
      min-height: 100%;
    }
  }

  @media screen {
    body {
      background-color: #fff;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
      box-sizing: border-box;
      margin: 1rem auto 0;
      max-width: 820px;
      min-height: calc(100vh - 1rem);
      padding: 4rem 6rem 6rem 6rem;
    }
  }
`;

export default class extends WysiwygController {
  static values = {
    css: String,
  };

  connect() {
    super.connect();
    console.log("Here");
  }

  get optionsValue() {
    return {
      content_style: styles,
      content_css: this.cssValue,
    };
  }
}
