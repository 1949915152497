import { Controller } from "@hotwired/stimulus";

import TomSelect from "tom-select/dist/js/tom-select.complete";

// Connects to data-controller="tom-select"
export default class extends Controller {
  static values = {
    maxItems: String,
  };

  connect() {
    this.element.classList = "";

    const maxItems = this.hasMaxItemsValue ? this.maxItemsValue : null;

    this.tomSelect = new TomSelect(this.element, {
      maxItems: maxItems,
    });

    this.tomSelect.on("item_add", (value) => {
      this.dispatch("selected", { detail: { value: value } });
    });
  }
}
