import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="practitioner-info"
export default class extends Controller {
  static values = { url: String };
  static targets = ["turbo", "selectBox"];

  connect() {
    if (this.hasSelectBoxTarget) {
      this.loadPractitionerInfo(this.selectBoxTarget.value);
    }
  }

  update(e) {
    this.loadPractitionerInfo(e.target.value);
  }

  loadPractitionerInfo(practitionerId) {
    if (!practitionerId) {
      this.turboTarget.innerHTML = "";
      return;
    }

    let url = this.urlValue.replace(":id", practitionerId);

    get(url, {
      responseKind: "turbo-stream",
    });
  }
}
