import { ApplicationController, useDebounce } from "stimulus-use";

export default class extends ApplicationController {
  static targets = ["sliderInputs", "responsesContainer", "keyInput"];

  static debounces = ["fieldNameChanged"];

  connect() {
    useDebounce(this);
  }

  fieldTypeChanged(e) {
    if (e.target.value === "Slider") {
      this.sliderInputsTarget.classList.remove("d-none");
    } else {
      this.sliderInputsTarget.classList.add("d-none");
    }

    if (
      e.target.value === "Radio" ||
      e.target.value === "Checkbox" ||
      e.target.value === "Medication"
    ) {
      this.responsesContainerTarget.classList.remove("d-none");
    } else {
      this.responsesContainerTarget.classList.add("d-none");
    }
  }

  fieldNameChanged(e) {
    let inputValue = e.target.value;
    if (inputValue) {
      const key = inputValue.replace(/[^a-z0-9]+/gi, "_").toLowerCase();
      this.keyInputTarget.value = key;
    }
  }
}
