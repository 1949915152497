import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";

// Connects to data-controller="reports"
export default class extends Controller {
  connect() {
    this.reportStateChanged = false;

    document
      .querySelector("#report-widget-container")
      .addEventListener("change", this.reportChanged.bind(this));

    document.querySelectorAll(".list-group-item-action").forEach(
      function (element) {
        element.addEventListener("click", this.checkReportState.bind(this));
      }.bind(this),
    );

    document
      .querySelector("#report-widget-container")
      .addEventListener("turbo:submit-end", this.reportSaved.bind(this));
  }

  reportChanged() {
    this.reportStateChanged = true;
  }

  checkReportState(event) {
    if (this.reportStateChanged) {
      event.preventDefault();

      Swal.fire({
        icon: "error",
        text: "Please save the current section you are working on before continuing",
      });

      return;
    }
  }

  reportSaved() {
    this.reportStateChanged = false;

    let currentSelection = document.querySelector(".current-selected-widget");
    currentSelection.classList.remove("bg-info");
    currentSelection.classList.remove("bg-opacity-50");
    currentSelection.classList.remove("current-selected-widget");

    currentSelection.classList.add("bg-success");
    currentSelection.classList.add("bg-opacity-75");
    currentSelection.classList.add("text-white");
  }
}
