import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="appointment-request-form"
export default class extends Controller {
  static values = {
    url: String,
  };

  static targets = ["requestedDate", "appointmentType"];

  patientChanged(event) {
    if (!event.detail.value) return;

    let url = this.urlValue;
    url += `/available_incidents?patient_id=${event.detail.value}`;

    get(url, {
      responseKind: "turbo-stream",
    });
  }

  locationChanged(event) {
    if (!event.target.value) return;

    let url = this.urlValue;
    url += `/available_appointment_types?location_id=${event.target.value}`;

    get(url, {
      responseKind: "turbo-stream",
    });
  }

  getAvailableTimes() {
    if (!this.appointmentTypeTarget.value) return;
    if (!this.requestedDateTarget.value) return;

    let url = this.urlValue;
    url += `/available_appointment_times?appointment_type_id=${this.appointmentTypeTarget.value}&requested_date=${this.requestedDateTarget.value}`;

    console.log(url);

    get(url, {
      responseKind: "turbo-stream",
    });
  }
}
