import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="signature-pad-receiver"
export default class extends Controller {
  static targets = ["input", "preview"];

  addSignature(signature) {
    this.inputTarget.value = signature;

    if (this.hasPreviewTarget) this._generateSignature();
  }

  _generateSignature() {
    let img = document.createElement("img");
    img.src = this.inputTarget.value;

    this.previewTarget.innerHTML = "";
    this.previewTarget.appendChild(img);
    this.previewTarget.classList.remove("d-none");
  }
}
